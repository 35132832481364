@import url(https://fonts.googleapis.com/css2?family=Comfortaa&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-toggler {
  padding: 10px 6px;

}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0rem;
}

.navbar-toggler span {
  display: block;
  height: 2px;
  border: 2px;
  background: rgb(33 82 148);
  ;
}

.navbar-toggler span+span {
  margin-top: 6px;
  /* width: 25px; */

}

nav .nav-item {
  padding: 10px 23px;
}

/* Remove border from toggler */
.navbar-toggler {
  border: 0 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
}


.toggler-icon {
  width: 30px;
  height: 3px;

  display: block;
  transition: all 0.5s;
}


.middle-bar {
  margin: 5px auto;
}


.navbar-toggler .top-bar {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: 10% 10%;
          transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-transform-origin: 10% 90%;
          transform-origin: 10% 90%;
}



.navbar-toggler.collapsed .top-bar {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  -webkit-transform: rotate(0);
          transform: rotate(0);
}
html,
body {

    top: 0;
    left: 0;
    margin: 0;
    font-family: 'Comfortaa';
    box-sizing: border-box;
    overflow-x: hidden;
}

/* Header Css Start */
.getdetail{
    padding: .3rem 2rem;
    background-color: #f0ae00;
    border-radius: 3rem;
    border: none;
    font-family: 'Comfortaa';
    font-weight: 600;
}

.eduCrm-sec{
    background-color: #F5F5F5;
   padding: 2.3rem 0rem;
    margin-top: 5rem;

}
.eduCrm-sec h2{
    text-align: center;
    padding-bottom: 1rem;
 
}
.eduCrm-sec p{
  padding: .4rem 0rem;
}

  /* education crm Css start */

  .eduction-crm {
      background-color: #0B1320;
      /* height: 200px; */
      color: #fff;
      padding-top: 1.5rem;
  }
  .eduction-crm h2 {
      padding-top: .5rem;
  }

  .eduction-crm h4{
      padding-top: 1rem ;
  }
  .rowsecound {
      padding: 3rem 0rem;
  }
  /* Crm paltform css Start */
  .main-herosection-images {
      position: relative;
            margin-top: 6rem;
  }
  .main-herosection-images img {
        box-shadow: 0.1rem 0 0.8rem rgb(0 0 0 / 25%);
      /* height: 75%;
      margin-top: 10rem;
      box-shadow: 0 0.8rem 0.8rem rgba(0, 0, 0, 0.25);
      border-radius: 1.5rem; */
      /* position: absolute; */
  }
  .main-herosection-images .main-hero-img2 {
        position: absolute;
     
        left: 39.2%;
        top: -27%;
        box-shadow: 0.1rem 0 0.8rem rgb(0 0 0 / 25%);
        
  }


  /* Footer Css Start */
  .footer {
      background-color: #0B1320;
      /* height: 400px; */
      margin-top: 4rem;
      padding: 4rem 0rem;
  }
  .forms{
      background-color: #fff;
      padding: 2rem 0rem;
      margin: 0rem 5rem;
      padding: 2rem 2rem;
     
     
  }
  .forms h4{
      font-weight: 600;
  }
  .forms input{
      background-color: rgb(228, 225, 225);
      width: 100%;
       border-radius: .2rem;
      height: 2.2rem;
      border: none;
      padding: 1.5rem .8rem;
  }
 
  .forms textarea{
      background-color: rgb(228, 225, 225);
       padding: 1rem .8rem;
      width: 100%;
      border-radius: .2rem;
      border: none;
  }

  .bottomfooter{
      text-align: center;
      
      }
  .bottomfooter img{
   padding-left: .9rem;
  
      
  }
  .bottomfooter h4{
      padding-top: 2rem;
  }
  .bottomfooter h4 img{
      margin-left: -10px;
  }



@media (max-width: 575.98px) { 
    .main-herosection-images .main-hero-img2 {
        position: absolute;
        left: 20.2%;
        top: -27%;  
  }
  .forms {
    margin: 0rem 0.5rem;
}
 }


@media (min-width: 576px) and (max-width: 767.98px) { 
    .main-herosection-images .main-hero-img2 {
        position: absolute;
     
        left: 20.2%;
        top: -27%;
        
  }
  .forms {
    margin: 0rem 0.5rem;
}
 }


@media (min-width: 768px) and (max-width: 991.98px) { 
    .main-herosection-images .main-hero-img2 {
        position: absolute;
     
        left: 20.2%;
        top: -27%;
        
  }
 }


@media (min-width: 992px) and (max-width: 1199.98px) { 
    .main-herosection-images .main-hero-img2 {
        position: absolute;
     
        left: 27.2%;
        top: -27%;
        
  }
 }


@media (min-width: 1200px) { 
    .main-herosection-images .main-hero-img2 {
        position: absolute;
     
        left: 39.2%;
        top: -27%;
        box-shadow: 0.1rem 0 0.8rem rgb(0 0 0 / 25%);
        
  }
 }





