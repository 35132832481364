.navbar-toggler {
  padding: 10px 6px;

}

.navbar-toggler:focus {
  box-shadow: 0 0 0 0rem;
}

.navbar-toggler span {
  display: block;
  height: 2px;
  border: 2px;
  background: rgb(33 82 148);
  ;
}

.navbar-toggler span+span {
  margin-top: 6px;
  /* width: 25px; */

}

nav .nav-item {
  padding: 10px 23px;
}

/* Remove border from toggler */
.navbar-toggler {
  border: 0 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
}


.toggler-icon {
  width: 30px;
  height: 3px;

  display: block;
  transition: all 0.5s;
}


.middle-bar {
  margin: 5px auto;
}


.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}



.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}